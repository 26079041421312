// JQuery
import $ from 'jquery';
import jQuery from 'jquery';
window.$ = jQuery;
window.jQuery = jQuery;

// JS
import 'jquery-match-height';
import 'lightgallery';
import 'slick-carousel';

import whatInput from 'what-input'

//foundation
import './foundation-modules.js';

// Show Old IE Notice
if(!!window.ActiveXObject) {
    $("#ieNotice").removeClass("hide");
}

// Mobile Nav
$('.menu-icon a').click(function() {
    $('header nav').toggleClass("open");
    $(this).toggleClass("open");
    return false;
});

(function(context){
    let breakPoint = 1024;
    $(context+" nav ul>li>a").click(function(event, force){
        if (whatInput.ask() === 'keyboard' || whatInput.ask() === 'touch' || $(window).width() < breakPoint) {
            if($(this).parent().find("ul").length > 0 && (($(window).width() >= breakPoint && $(this).parent().hasClass('level-1')) || $(window).width() < breakPoint)) {
                if (!$(this).parent().hasClass('open')) {
                    $(this).closest("ul").find('.open').removeClass('open');
                    $(this).parent().addClass('open');
                } else {
                    $(this).parent().removeClass('open');
                }
                event.preventDefault();
            } else if (force) {
                window.location = $(this).attr('href');
            }
            event.stopPropagation();
        }
    }).keydown(function(event){
        if(whatInput.ask() === 'keyboard' && event.keyCode === 32){
            event.preventDefault();
            $(this).trigger('click', true);
        }
    });

    $(context+" .menu-icon a").keydown(function(event){
        if(whatInput.ask() === 'keyboard' && event.keyCode === 32){
            event.preventDefault();
            $(this).trigger('click', true);
        }
    });

    $("body").click(function(event){
        $(context).find('.open').removeClass('open');
    });

    if($(window).width() < breakPoint) {
        $(context+" nav ul ul .active").each(function(){
            $(this).parent().parent().addClass('open');
        });
    }

})(("header "));

if($('.slider').length) {
    $('.slider').slick({
       prevArrow:'<button type="button" data-role="none" class="button icon-left-open-big slick-prev" aria-label="Previous" tabindex="0" role="button"></button>',
        nextArrow:'<button type="button" data-role="none" class="button icon-right-open-big slick-next" aria-label="Next" tabindex="0" role="button"></button>',
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: false
    });
}

if($(".lg-images").length) {
    $(".lg-images").lightGallery({
        selector: 'a'
    });
}

$(document).foundation();


if($('.staff-list').length) {
    $('.staff-list > div').slick({
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
}

if($('.submenu').length) {
    $('.submenu').slick({
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
}

$('.services-list h2').matchHeight();
$('.services-list p').matchHeight();
$('.story .wrapper').matchHeight();
$('.more-content .wrapper').matchHeight();
$('.staff-list .details').matchHeight();
